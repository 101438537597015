document.addEventListener("DOMContentLoaded", function (event) {
  const block = document.querySelector(".block-contact");
  const svg = document.querySelector(".block-contact svg");
  const form = document.querySelector(".block-contact form");
  const button = document.querySelector(".block-contact button");

  gsap.registerPlugin(ScrollTrigger);

  gsap.to(block, {
    scrollTrigger: {
      trigger: block,
      start: "top bottom-=200px", // Trigger animation when block is 200px in view
      end: "bottom center",
      // once: true,
      onEnter: (self) => {
        svg.classList.add("show");
        form.classList.add("show");
        button.classList.add("show");
      },
    },
  });
});
